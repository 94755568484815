/* General Page Styling */
.our-work-page {
    max-width: 1200px;
    margin: 20px auto 0 auto; /* Only set margin-top to 20px */
    /* padding: 20px; */
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Arial", sans-serif;
    text-align: center;
  }
  
  .our-work-title {
    font-size: 32px;
    color: #2c3e50;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .our-work-intro {
    font-size: 18px;
    color: #555555;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* Grid Layout for Services */
  .work-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  /* Individual Service Cards */
  .work-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .work-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .work-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .work-title {
    font-size: 20px;
    color: #2c3e50;
    margin: 15px 0 10px;
  }
  
  .work-description {
    font-size: 16px;
    color: #555555;
    padding: 0 15px 20px;
    line-height: 1.6;
  }
  
  /* General styling for the Donate Now button */
.donate-button {
  position: fixed;
  bottom: 20px;
  margin-bottom: 3cm;
  right: 20px;
  background-color: #00bcd4; /* Bright blue for the button */
  color: white;
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.donate-button:hover {
  background-color: #0097a7; /* Darker blue on hover */
  transform: translateY(-5px);
}

.donate-button:active {
  background-color: #00838f; /* Even darker blue when clicked */
  transform: translateY(2px);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .donate-button {
      bottom: 15px;
      right: 15px;
      font-size: 16px;
      padding: 12px 25px;
  }
}

/* Tablet and medium screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .donate-button {
      bottom: 20px;
      right: 20px;
      font-size: 18px;
      padding: 14px 28px;
  }
}