/* General container styling */
.media-container {
  padding: 0px 20px 20px 20px;
  background-color: #f9f9f9;
}

/* Section styling */
.media-section {
  margin-bottom: 40px;
}

.media-section-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  padding: 10px;
  color: #333;
  background: linear-gradient(90deg, #d4fc79, #96e6a1);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

/* Row styling for images in each section */
.media-row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap; /* Allows wrapping to new rows if needed */
  justify-content: center;
}

/* Individual media item styling */
.media-item {
  flex: 0 0 calc(25% - 16px); /* 4 items per row (25% width, minus the gap) */
  height: 200px; /* Fixed height for rectangular shape */
  max-width: calc(25% - 16px);
  border-radius: 8px; /* Optional: rounded corners */
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.media-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits inside the rectangular area */
  transition: transform 0.3s, opacity 0.3s;
}

.media-item:hover img {
  transform: scale(1.05);
  opacity: 0.8;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 15, 15, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
}

/* Modal content styling */
.modal-content {
  position: relative;
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 16px;
}

/* Close button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 10px 15px;
  transition: transform 0.3s, background 0.3s;
}

.close:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #333;
}

/* Navigation buttons */
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #333;
}

/* 
@media (max-width: 768px) {
  .media-item {
    flex: 0 0 calc(50% - 16px); 
    height: 150px; 
  }
 
  .media-section-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .media-item {
    flex: 0 0 calc(100% - 16px); 
    height: 200px;
  }

  .media-section-title {
    font-size: 1.2rem;
  }

  .modal-content {
    width: 100%;
    max-width: none;
    margin: 0 10px;
  }

  .prev-button,
  .next-button {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
}  */

/* General styling for the Donate Now button */
.donate-button {
  position: fixed;
  bottom: 20px;
  margin-bottom: 3cm;
  right: 20px;
  background-color: #00bcd4; /* Bright blue for the button */
  color: white;
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.donate-button:hover {
  background-color: #0097a7; /* Darker blue on hover */
  transform: translateY(-5px);
}

.donate-button:active {
  background-color: #00838f; /* Even darker blue when clicked */
  transform: translateY(2px);
}


/* @media (max-width: 767px) {
  .donate-button {
      bottom: 15px;
      right: 15px;
      font-size: 16px;
      padding: 12px 25px;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .donate-button {
      bottom: 20px;
      right: 20px;
      font-size: 18px;
      padding: 14px 28px;
  }
} */


/* Media queries for smaller screens */
@media (max-width: 768px) {
  .media-item {
    flex: 0 0 calc(50% - 16px); /* 2 items per row */
    height: 150px; /* Reduce height for better fit */
  }

  .media-section-title {
    font-size: 1.5rem;
    margin: 10px 0;
  }

  .modal-content {
    width: 90%; /* Ensure it fits well within smaller screens */
    max-width: 400px;
    margin: 0 auto;
  }

  .prev-button,
  .next-button {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  /* Adjusting image grid for smaller devices */
  .media-item {
    flex: 0 0 calc(50% - 16px); 
    height: 150px; 
  }

  /* Adjusting the media section title font size */
  .media-section-title {
    font-size: 1.5rem;
  }

  /* Make modal image and buttons responsive */
  .modal-content {
    width: 90%;
  }

  .prev-button,
  .next-button {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  .modal-image {
    width: 100%;
    max-width: 400px; /* Adjust image width */
  }

  /* Making sure the donate button is responsive */
  .donate-button {
    font-size: 16px;
    padding: 12px 25px;
    bottom: 15px;
    right: 15px;
  }
}

/* Further media query for smaller screens */
@media (max-width: 480px) {
  /* Adjusting layout for very small devices */
  .media-item {
    flex: 0 0 calc(100% - 16px); 
    height: 150px; /* Make images smaller */
  }

  .media-section-title {
    font-size: 1.2rem;
  }

  .modal-content {
    width: 100%;
    max-width: none;
  }

  .prev-button,
  .next-button {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  .donate-button {
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    padding: 10px 20px;
  }
}
