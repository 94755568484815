/* Carousel Caption Styling */
.carousel-caption {
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
}

.carousel-caption-heading {
    font-size: 2rem; /* Larger font size for heading */
    color: #ffffff; /* Bright white for contrast */
    font-weight: bold; /* Bold font for emphasis */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Subtle text shadow */
    margin-bottom: 10px;
}

.carousel-caption-text {
    font-size: 1.2rem; /* Larger, readable font size for text */
    color: #e0e0e0; /* Light gray for a softer appearance */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Text shadow for readability */
    line-height: 1.6; /* Better line spacing */
}


/* General styling for the Donate Now button */
.donate-button {
    position: fixed;
    bottom: 20px;
    margin-bottom: 3cm;
    right: 20px;
    background-color: #00bcd4; /* Bright blue for the button */
    color: white;
    font-size: 18px;
    padding: 15px 30px;
    border-radius: 50px;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.donate-button:hover {
    background-color: #0097a7; /* Darker blue on hover */
    transform: translateY(-5px);
}

.donate-button:active {
    background-color: #00838f; /* Even darker blue when clicked */
    transform: translateY(2px);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
    .donate-button {
        bottom: 15px;
        right: 15px;
        font-size: 16px;
        padding: 12px 25px;
    }
}

/* Tablet and medium screens */
@media (min-width: 768px) and (max-width: 1024px) {
    .donate-button {
        bottom: 20px;
        right: 20px;
        font-size: 18px;
        padding: 14px 28px;
    }
}

/* Carousel Styling */
.carousel-inner {
    height: 100%;
}

.carousel-item img {
    height: 100%;
    object-fit: cover;
}

/* Banner Section */
.banner {
    background-color: #f8f9fa;
    text-align: center;
    padding: 3rem 1rem;
    margin-top: 2rem;
    border-top: 5px solid #ff9800;
    border-bottom: 5px solid #ff9800;
}

.banner-heading {
    font-size: 2rem;
    color: #ff5722;
    margin-bottom: 1rem;
    font-weight: bold;
}

.banner-text {
    font-size: 1.2rem;
    color: #444;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
}

/* Donate Now Button Section */
.donate-section {
    text-align: center;
    margin-top: 3rem;
}

.donate-button {
    background-color: #3b4cca;  /* Adjust color as needed for your theme */
    color: white;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 30px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;
}

.donate-button:hover {
    background-color: #2a3a9b;  /* Slightly darker shade on hover */
    transform: translateY(-5px); /* Button lift effect */
}

.donate-button:active {
    background-color: #1b2d7a;  /* Even darker shade when button is clicked */
}

.donate-button:focus {
    outline: none;
}

/* Certificates Section */
.certificates-section {
    text-align: center;
    padding: 2rem 1rem;
    background-color: #e9ecef;
}

.certificates-heading {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #333;
}

.certificates {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.certificate-card {
    width: 250px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.certificate-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.certificate-img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
    object-fit: cover;
}

.certificate-name {
    font-size: 1rem;
    color: #555;
    font-weight: bold;
}
