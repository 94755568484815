.contact-container {
    max-width: 1100px;
    margin: 20px auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    font-family: "Arial", sans-serif;
}

/* Header section */
.contact-header {
    font-size: 2.8rem;
    text-align: center;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
}

.contact-intro {
    text-align: center;
    font-size: 1.3rem;
    color: #666;
    margin-bottom: 25px;
}

/* Contact details layout */
.contact-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}

.contact-info {
    flex: 1 1 calc(60% - 20px);
    background: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-social {
    flex: 1 1 calc(35% - 20px);
    background: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Table Styling */
.contact-table {
    width: 100%;
    border-collapse: collapse;
}

.contact-table tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.contact-label {
    font-weight: bold;
    width: 40%;
    color: #333;
}

.contact-value {
    width: 60%;
    color: #555;
    word-wrap: break-word; /* Ensures long text wraps properly */
    word-break: break-word; /* Breaks words if necessary */
}

.contact-value a {
    color: #007BFF;
    text-decoration: none;
    overflow-wrap: break-word; /* Ensures long links break within the container */
}

.contact-value a:hover {
    text-decoration: underline;
}


/* Social Icons */
.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.social-icons a {
    font-size: 2.5rem;
    color: #007BFF;
    transition: all 0.3s ease;
}

.social-icons a:hover {
    color: #0056b3;
    transform: scale(1.15);
}

.contact-map {
    margin-top: 30px;
    text-align: center;
}

.map-container {
    margin-bottom: 20px;
}

.map-label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.contact-map iframe {
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 300px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-details {
        flex-direction: column;
    }

    .contact-info, .contact-social {
        flex: 1 1 100%;
    }

    .contact-header {
        font-size: 2.2rem;
    }

    .contact-intro {
        font-size: 1.1rem;
    }
}
