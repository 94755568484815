.about-us-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 1000px;
  margin: 40px auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* Hover Effect */
.about-us-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.about-us-image-container {
  flex: 1;
  text-align: center;
}

.about-us-image {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-text {
  flex: 2;
  color: #333333;
  font-family: "Arial", sans-serif;
  line-height: 1.8;
}

.about-us-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #2c3e50;
  text-transform: uppercase;
}

.about-us-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555555;
}

.about-us-vision {
  margin-top: 30px;
}

.about-us-vision-title {
  font-size: 24px;
  font-weight: bold;
  color: #007BFF;
  margin-bottom: 15px;
}

.about-us-vision-description {
  font-size: 16px;
  color: #555555;
  margin-bottom: 20px;
}

.about-us-donation {
  font-size: 16px;
  margin-bottom: 20px;
  color: #007BFF;
  font-weight: bold;
}

/* General styling for the Donate Now button */
.donate-button {
  position: fixed;
  bottom: 20px;
  margin-bottom: 3cm;
  right: 20px;
  background-color: #00bcd4;
  color: white;
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.donate-button:hover {
  background-color: #0097a7;
  transform: translateY(-5px);
}

.donate-button:active {
  background-color: #00838f;
  transform: translateY(2px);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  /* Stack layout vertically */
  .about-us-card {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  /* Center the logo at the top */
  .about-us-image-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .about-us-image {
    max-width: 200px;  /* Reduce size of image for mobile view */
  }

  /* Adjust text layout */
  .about-us-text {
    text-align: center;
    max-width: 90%;
  }

  .about-us-title {
    font-size: 30px; /* Increased font size for mobile */
  }

  .about-us-description {
    font-size: 18px; /* Increased font size for mobile */
  }

  .about-us-vision-title {
    font-size: 26px; /* Increased font size for mobile */
  }

  .about-us-vision-description {
    font-size: 18px; /* Increased font size for mobile */
  }

  .about-us-donation {
    font-size: 18px; /* Increased font size for mobile */
  }

  /* Adjust the Donate Now button for mobile */
  .donate-button {
    bottom: 15px;
    right: 15px;
    font-size: 18px;
    padding: 12px 25px;
  }
}

/* Tablet and medium screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .donate-button {
    bottom: 20px;
    right: 20px;
    font-size: 18px;
    padding: 14px 28px;
  }
}
